import React from 'react';
import { Card, CardMedia, Container, Link, Typography } from '@mui/material';
import photos from './photos_1.png';

const Pictures: React.FC = () => {
    return (
<Link id="pictures" style={{textDecoration: "none"}}>
        <Card className='content-card'>
        <CardMedia style={{ height: "400px"}} image={photos} >
            <Container className="content-card-container" sx={{position: 'flex', alignContent: 'center',}}>
            <Typography variant='h2'>Bilder</Typography>
                
                <Typography variant='body1'>
                Viele, viele Bilder und Videos haben diesen Abend festgehalten - <Link href="https://photos.app.goo.gl/YTw3sTy6PkwBSbuo9">hier könnt ihr sie teilen</Link>.
                <br /><br />
                <Link href="https://photos.app.goo.gl/rZQriNXMJgTHBmoi7">Hier</Link> sind die Bilder, die ihr schon vorher geteilt habt.
                <br /><br />
                Und die <Link href="../moments_since_1974.pdf" download={true}>Slideshow könnt ihr natürlich auch herunterladen</Link> :-)
                </Typography>
            </Container>
        </CardMedia>
    </Card>             
</Link>

    );
};

export default Pictures;
