import { Box, AppBar, Toolbar, IconButton,} from '@mui/material';
import React from 'react';
import {Link} from 'react-scroll';
import LibraryMusic from '@mui/icons-material/LibraryMusic';
import Collections from '@mui/icons-material/Collections';
import BarChart from '@mui/icons-material/BarChart';




const Header: React.FC = () => {
    return (
        <AppBar sx={{position: 'sticky', top: '0'}}>
            <Toolbar>
            <Box sx={{ display: { xs:'flex', sm: 'block' } }} margin={'auto'}>
            
            <Link to="pictures" offset={-70} smooth duration={500}>
                <IconButton  className="icon-button" aria-label='Bilder' color="secondary" sx={{ color: '#fff' }}>
                  <Collections />
                  <div className="icon-button-label">Bilder</div>
                </IconButton>
              </Link>
              <Link to="music" offset={-70} smooth duration={500}>
                <IconButton  className="icon-button" aria-label='Musik' color="secondary" sx={{ color: '#fff' }}>
                  <LibraryMusic />
                  <div className="icon-button-label">Musik</div>
                </IconButton>
              </Link>
              <Link to="statistics" offset={-70} smooth duration={500}>
                <IconButton  className="icon-button" aria-label='Statistik' color="secondary" sx={{ color: '#fff' }}>
                  <BarChart />
                  <div className="icon-button-label">Statistik</div>
                </IconButton>
              </Link>


          </Box>                
            </Toolbar>
        </AppBar>
    );
};

export default Header;
