import React, { Component } from 'react';
import {  Box, Grid } from '@mui/material';


type Prop = {
};


export default class Footer extends Component<Prop>  {
    constructor(props: Prop) {
        super(props);
        console.log("constructing Login page ...");
    }


    
    render() {
        return (
            <React.Fragment >
                <Grid className="footer" container>
                    <Grid className="footer-left" xs={3} md={2} display="flex">
                        <div>Impressum</div>
                    </Grid>
                    <Grid className="footer-middle" xs={6} md={8} display="flex">
                        <Box display="flex">
                            <div ></div>
                        </Box>
                    </Grid>
                    <Grid className="footer-right" xs={3} md={2} >
                        <img src='../md.svg' alt="md" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </Grid>
                </Grid>

            </React.Fragment>
        )
    };

};

