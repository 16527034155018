import './App.css';
import Music from './components/party/music';
import Pictures from './components/party/pictures';
import Statistics from './components/party/statistics';
import { Container, ThemeProvider } from '@mui/material';
import Login from './components/invite/login';
import React from 'react';
import { Component } from 'react';
import AuthService from './services/auth.service';
import EventBus from './common/EventBus';
import Header from './components/party/header';
import Footer from './components/party/footer';
import { appTheme } from './themes/theme';

interface IProps { };

interface IState {
  loggedIn: boolean;
}

class App extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.logout = this.logout.bind(this);
    this.loginChanged = this.loginChanged.bind(this);

    this.state = {
      loggedIn: false
    };
  }

  componentDidMount() {
    const loggedIn = AuthService.isLoggedIn();

    this.setState({
      loggedIn: loggedIn
    });
    console.log("App did mount - loggedIn: " + loggedIn);

    EventBus.on("logout", this.logout);
  }

  componentWillUnmount() {
    EventBus.remove("logout", this.logout);
    this.logout();
    console.log("App unmounting - loggedIn: " + AuthService.isLoggedIn());
  }


  logout() {
    AuthService.logout();
    this.loginChanged();
  }

  loginChanged() {
    this.setState({
      loggedIn: Boolean(AuthService.isLoggedIn).valueOf()
    });
    console.log("App login changed to " + this.state.loggedIn);

    // add the currently logged in user to Dynatrace RUM
    if (this.state.loggedIn && AuthService.getEmailaddress() && window.dtrum) {
      window.dtrum.identifyUser(AuthService.getEmailaddress() as string);
    }
  }


  render() {
    // const loggedIn = AuthService.isLoggedIn();
    //let loggedIn : boolean = Boolean({ boolean: this.state.loggedIn});

    console.log("App rendering - loggedIn: " + this.state.loggedIn);
    if (this.state.loggedIn === false) {
      window.dtrum?.actionName("login");
      return (<Login loginHandler={this.loginChanged} />);
    } else {
      window.dtrum?.actionName("content-view");
      return (

        <ThemeProvider theme={appTheme}>
          <Container className="page">
            <Header ></Header>
            <Container className="page-content">
              <Pictures />
              <Music />
              <Statistics />
            </Container>
            <Footer></Footer>
          </Container>
        </ThemeProvider>
      );
    }
  }
}

export default App;
