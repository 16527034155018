import React from 'react';
import music from './dieter50_bandgeorg.jpg';

import {Card, CardMedia, Container, Link, Stack, Typography } from '@mui/material';
import { MusicNoteTwoTone, SpeakerGroupTwoTone, NightlifeTwoTone } from '@mui/icons-material';

const Music: React.FC = () => {
    return (
        <Link id="music" style={{textDecoration: "none"}}>
        
        <Card className='content-card' >
        <CardMedia style={{ height: "400px"}} image={music} >
            <Container className="content-card-container" sx={{position: 'flex', alignContent: 'center',}}>
            <Typography variant='h2'>Musik</Typography>
                <Typography variant='body1'>
                    Danke an <Link href="https://www.robhirschlive.at/">Rob Hirsch</Link>, Anna Riepl und Uwe Rath für den unvergesslichen Auftritt!
                    <br />
                    Ihr habt den Abend gerockt (... und gebluesed, und ge-austro-popped, und ...) - es war <b><u>legendär</u></b>!
                    <br /><br />
                    Hier sind die Links zu den Spotify-listen (die wir eigentlich gar nicht gebraucht hätten) - zum Nachhören und Ergänzen:
                    <Stack direction={'row'} alignItems={'center'} gap={1} width={'fit-content'} margin={'auto'}>
                        <NightlifeTwoTone className="icon-list"/>
                        <Link href="https://open.spotify.com/playlist/3eUedwNV5C2vEHFP5k481I?si=b44da8d4f0014377&pt=5cdb7b1ed69990b6c1b96bea3f13e525">Der gemütliche Einstieg</Link>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} gap={1} width={'fit-content'} margin={'auto'}>
                        <SpeakerGroupTwoTone className="icon-list"/>
                        <Link href="https://open.spotify.com/playlist/6h8f3TksRV0t5L7q8a261R?si=3d7a30e9c70b4ba9&pt=a0929eb1712cb87282d52677b6b5fe91">Die Pausenmusik</Link>
                    </Stack>
                </Typography>
            </Container>
        </CardMedia>
    </Card>             
    </Link>
    );
};

export default Music;
