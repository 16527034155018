import LockOpen from '@mui/icons-material/LockOpen';
import { InputLabel, Input, InputAdornment, Typography, Button, AlertTitle, Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { Component } from 'react';
import { FormEvent } from 'react';
import AuthService from "../../services/auth.service";
import Alert from '@mui/material/Alert';
import ReCAPTCHA from 'react-google-recaptcha'
import { Email } from '@mui/icons-material';



type Props = {
  loginHandler: () => void
};

type State = {
  redirect: string | null,
  accessCode: string,
  loading: boolean,
  message: string,
  captchaSuccessful: boolean,
  emailAddress: string
};

const emailRegExp: RegExp = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');


export default class Login extends Component<Props, State> {


  constructor(props: Props) {
    super(props);
    console.log("constructing Login page ...");
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);

    this.state = {
      redirect: null,
      accessCode: "",
      loading: false,
      message: "",
      captchaSuccessful: false,
      emailAddress: ""
    }
  }

  isValidEmail() {
    return emailRegExp.test(this.state.emailAddress);
  }

  captchaChanged(token: string | null) {
    this.setState({ captchaSuccessful: (token != null ? true : false) });
    console.log("captcha changed: " + token);
  }


  componentDidMount() {
    const loggedIn = AuthService.isLoggedIn();
    console.log("mounted login: " + loggedIn);
    if (loggedIn) {
      this.setState({ redirect: null });
    };
  }

  componentWillUnmount() {
    console.log("unmounting login");
    //window.location.reload();
  }


  handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const accessCode = this.state.accessCode;
    console.log("entered code: " + accessCode);
    if (AuthService.login(accessCode, this.state.emailAddress)) {
      window.dtrum?.actionName("successfull login");
      this.props.loginHandler();
    } else {
      this.setState({
        redirect: null,
        message: "The code you entered wasn't correct"
      });
      window.dtrum?.actionName("failed login");
    }
    console.log("handled submit: " + this.state);

  }

  render() {
    console.log("rendering login" + this.state.redirect);
    if (this.state.redirect) {
      console.log("redirecting to " + this.state.redirect);
      //return <Navigate to={this.state.redirect} />
    }
    const { message } = this.state;
    return (

      <Grid item m={6} xs={12}>
        <Box m={1} display="flex" alignItems="center" flexDirection="column">
          <Card className="login-root" >
            <Box component="form" sx={{ '& > :not(style)': { m: 1, width: '25ch' }, }} noValidate autoComplete="off" onSubmit={this.handleSubmit}>

              {message && (
                <Alert severity="error" >
                  <AlertTitle>Nope :-(</AlertTitle>
                  {message}
                </Alert>
              )}

              <Typography variant="h2" component="h2">Please log in</Typography>
              <InputLabel htmlFor="email">
                Email Adresse:
              </InputLabel>
              <Input
                id="email"
                name="email"
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
                onChange={e => this.setState({ emailAddress: e.target.value })}
                value={this.state.emailAddress}
              />
              <InputLabel htmlFor="accessCode">
                Access code
              </InputLabel>
              <Input
                id="accessCode"
                name="accessCode"
                startAdornment={
                  <InputAdornment position="start">
                    <LockOpen />
                  </InputAdornment>
                }
                onChange={e => this.setState({ accessCode: e.target.value })}
              />
              <Button type='submit' /*disabled={!this.state.captchaSuccessful}*/ disabled={!this.isValidEmail()}>login</Button>
            </Box>
          </Card>
        </Box>
      </Grid>
    );
  };

  /*<ReCAPTCHA 
  sitekey  = "" { process.env.RECAPTCHA_WEBSITE_KEY }
   onChange = { this.captchaChanged }
/>*/
};
