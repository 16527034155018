/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';

const API_URL = "http://localhost:8080/api/auth/";

class AuthService {
  login(accessCode: string, email: string) {
    const matches: string =  (accessCode === "19740104"?"true":"false");
    localStorage.setItem("loggedIn", matches);
    if (matches) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }
    return (matches==="true");
  }

  logout() {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("email");
  }

  isLoggedIn() {
    const loggedIn = localStorage.getItem("loggedIn");
    return (loggedIn === "true");
  }

  getEmailaddress() {
    return localStorage.getItem("email");
  }

}

export default new AuthService();
