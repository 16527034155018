import React from 'react';
import statistics from './statistics_1.png';

import { Card, CardMedia, Container, Grid, Link, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import MediaQuery from 'react-responsive';

const chartStyle = {
    //change left yAxis label styles
    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
        strokeWidth: 0.4,
        fill: "#ffffff"
    },
    // change all labels fontFamily shown on both xAxis and yAxis
    "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
        fontFamily: "Roboto",
    },
    // change bottom label styles
    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
        strokeWidth: 0.4,
        fill: "#ffffFF"
    },
    // bottomAxis Line Styles
    "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
        stroke: "#ffffff",
        strokeWidth: 0.4
    },
    // leftAxis Line Styles
    "& .MuiChartsAxis-left .MuiChartsAxis-line": {
        stroke: "#ffffff",
        strokeWidth: 0.4
    },
    // Y-ticks
    "& .MuiChartsAxis-left .MuiChartsAxis-tick": {
        stroke: "#ffffff",
        strokeWidth: 0.4
    },
    // X-ticks
    "& .MuiChartsAxis-bottom .MuiChartsAxis-tick": {
        stroke: "#ffffff",
        strokeWidth: 0.4
    },
    "& .MuiChartsAxis-bottom .MuiChartsAxis-label": {
        fill: "#ffffff"
    },
}

const Statistics: React.FC = () => {
    return (
        <Link id="statistics" style={{ textDecoration: "none" }}>

            <Card className='content-card'>
                <CardMedia style={{ height: "400px" }} image={statistics} >
                    <Container className="content-card-container" sx={{ position: 'flex', alignContent: 'center', height: 'auto' }}>
                        <Typography variant='h2'>Statistik</Typography>
                        <Typography variant='body1'>
                            Für kommende Parties, hier ein paar harte Fakten!
                        </Typography>
                        <MediaQuery query="(min-width: 1024px)">
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <BarChart
                                        sx={chartStyle}
                                        xAxis={[
                                            {
                                                id: 'beverages',
                                                data: ['Bier', 'Wein', 'Saft', 'Tee'],
                                                scaleType: 'band',
                                                label: 'Trinken',
                                            },
                                        ]}
                                        series={[
                                            {
                                                data: [43, 14, 8, 0.1],
                                                label: 'Liter',
                                            },
                                        ]}
                                        slotProps={
                                            {
                                                legend: {
                                                    labelStyle: {
                                                        fill: "#ffffff"
                                                    }
                                                }
                                            }
                                        }
                                        width={300}
                                        height={300}
                                        tooltip={{ trigger: 'item' }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BarChart
                                        sx={chartStyle}

                                        xAxis={[
                                            {
                                                id: 'food',
                                                data: ['Chili', 'Chili (v)', 'Jause', 'Kuchen'],
                                                scaleType: 'band',
                                                label: 'Essen',
                                            },
                                        ]}
                                        series={[
                                            {
                                                data: [30, 15, 45, 35],
                                                label: 'Portionen',
                                            },
                                        ]}
                                        slotProps={
                                            {
                                                legend: {
                                                    labelStyle: {
                                                        fill: "#ffffff"
                                                    }
                                                }
                                            }
                                        }

                                        width={300}
                                        height={300}
                                        tooltip={{ trigger: 'item' }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <BarChart
                                        sx={chartStyle}
                                        xAxis={[
                                            {
                                                id: 'mood',
                                                data: ['6-8 Uhr', '8-10 Uhr', '10-3 Uhr'],
                                                scaleType: 'band',
                                                label: 'Stimmung',
                                            },
                                        ]}
                                        series={[
                                            {
                                                data: [100, 150, 300],
                                                label: '%',
                                            },
                                        ]}
                                        slotProps={
                                            {
                                                legend: {
                                                    labelStyle: {
                                                        fill: "#ffffff"
                                                    }
                                                }
                                            }
                                        }

                                        width={300}
                                        height={300}
                                        tooltip={{ trigger: 'item' }}
                                    />
                                </Grid>
                            </Grid>
                        </MediaQuery>
                        <MediaQuery query="(max-width: 1023px)">
                            <Typography variant='body1'>
                                Am besten am breiten Bildschirm anschauen - kurze Zusammenfassung:
                                <br /><br />
                                43l Bier, 14l Wein, 8l Saft und 1 Tee
                                <br />
                                30 x Chili, 15 x v-Chili, 35 x Kuchen
                                <br />
                                Beste Stimmmung bis 03:45!                            
                            </Typography>
                        </MediaQuery>

                    </Container>
                </CardMedia>
            </Card>
        </Link>
    );
};

export default Statistics;
