import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
 
export const appTheme = createTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
  components: {
    MuiTypography: {
        defaultProps: {
            color: "#ffffff",
            alignContent: "center"
        }
    },
    MuiCardMedia: {
        defaultProps: {
            
        },
        styleOverrides: {
            
        },
    }, 
    MuiLink: {
      defaultProps: {
        color: "#afe6ff",
        underline: "hover"
      }
    }
    },
    typography: {
      h1: {
        fontSize: "1.5em",
        '@media (min-width:600px)': {
          fontSize: '2.5rem',
        },
      },
      h2: {
        fontSize: "1.3em",
        '@media (min-width:600px)': {
          fontSize: '2.0rem',
        },
      },
      h3: {
        fontSize: "1.2em",
        '@media (min-width:600px)': {
          fontSize: '1.8rem',
        },
      },
      body1: {
        fontSize: "0.8em",
        '@media (min-width:600px)': {
          fontSize: '1.3rem',
        },
      },
      body2: {
        fontSize: "0.7em",
        '@media (min-width:600px)': {
          fontSize: '1.3rem',
        },
      },
    }
 
}
);

export {};